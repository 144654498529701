import * as React from "react"
import { navigate } from "gatsby";
import styled from 'styled-components'
import {Container, Row, Col, Button} from 'react-bootstrap'
// import { Layout } from "../components/layout"
// import { heading, paragraph, container } from "./404.module.css"
import Img404 from '../../static/ErrorAlienSpaceship.svg'

const GoBack = styled(Button)`
  background: ${({theme}) => theme.accent.primary};
  color: ${({theme}) => theme.textAlt};
  border: 0;

  &:hover{

  }
`

export default function NotFoundPage() {
  return (
    <Container>
      <Row>
        <Col md={12} className="d-flex flex-column justify-content-center align-items-center">
          <img src={Img404} alt="404 Page Not found" height="400" width="400" />
          <p>Sorry! This is not valid request</p>
          <GoBack onClick={() => navigate(-1)}>Go Back</GoBack>
        </Col>
      </Row>
    </Container>
  )
}
